import axios from "axios";
import { sapienRoute } from "@/inertia-utils/hooks";

const defaultOnUploadProgress = (event: ProgressEvent) => {
    const percentCompleted = Math.round(
        (event.loaded * 100) / event.total
    );
    console.log("onUploadProgress", percentCompleted);
};

const upload = async (
    files: File[],
    onUploadProgress?: (event: ProgressEvent) => number | void
): Promise<string | unknown> => {
    const formData = new FormData();
    formData.append("upload", files[0]);

    return axios
        .post<{ url: string }>(
            sapienRoute("creator.upload-image"),
            formData,
            {
                headers: {
                    "Content-Type": "multipart/form-data"
                },
                onUploadProgress: onUploadProgress
                    ? onUploadProgress
                    : defaultOnUploadProgress
            }
        )
        .then((response) => {
            return response?.data?.url;
        })
        .catch((error) => {
            console.error(error);
        });
};

const uploadVideo = async (
    contentBlockId: string,
    files: File[],
    onUploadProgress?: (event: ProgressEvent) => number | void
): Promise<string | unknown> => {
    // const transport = new Transport(
    //     306,
    //     [],
    //     true,
    //     "/",
    //     onUploadProgress ? onUploadProgress : defaultOnUploadProgress
    // );
    const data = new FormData();
    data.append("content_block_id", contentBlockId);
    data.append("upload", files[0]);

    try {
        return axios
            .post<{ url: string }>(
                sapienRoute("creator.upload-video"),
                data,
                {
                    headers: {
                        "Content-Type": "multipart/form-data"
                    },
                    onUploadProgress: onUploadProgress
                        ? onUploadProgress
                        : defaultOnUploadProgress
                }
            )
            .then((response) => {
                console.log("upload", response)
                return response?.data?.url;
            })
            .catch((error) => {
                console.error(error);
            });
    } catch (error) {
        console.error(error);
        return error;
    }
};

export { upload, uploadVideo };
