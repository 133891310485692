import {
    ContentBlockShape,
    OptionShape,
    PromptShape,
    PromptType,
    RichText,
} from "@/models";
import React, { ChangeEvent, useMemo, useState } from "react";
import LessonRichText from "../LessonRichText";
import { PlusIcon, Settings2Icon } from "lucide-react";
import {
    useSaveOption,
    useSaveOptionScoring,
} from "../../LessonDesign/content-block-management/useSaveOption";
import { SliderMinAndMax } from "../../LessonDesign/QuestionForms";
import { Submission, useSelections } from "../../shared-state/useSelections";
import {
    Popover,
    PopoverContent,
    PopoverPortal,
    PopoverTrigger,
} from "@radix-ui/react-popover";
import Input from "@/components/Input";
import { debounce } from "@/hooks";

type Props = {
    editable: boolean;
    content: RichText;
    contentBlock: ContentBlockShape;
};

type OptionProps = Props & {
    selections: Submission[];
};

function OptionScoring({
    option,
    promptType,
}: {
    option: OptionShape;
    promptType: PromptType;
}) {
    const { mutateAsync } = useSaveOptionScoring();

    const save = debounce(mutateAsync, 500);
    return (
        <Popover>
            <PopoverTrigger>
                <button className="rounded-md bg-slate-200 p-2 hover:bg-white hover:shadow">
                    <Settings2Icon className="h-4 w-4" />
                </button>
            </PopoverTrigger>
            <PopoverContent
                className="rounded-md border bg-white p-4 shadow"
                align="center"
                side="left"
                sideOffset={10}
            >
                <div className="flex flex-col space-y-2">
                    <div className="flex items-center gap-x-3">
                        <Input
                            handleChange={(
                                e: ChangeEvent<HTMLInputElement>,
                            ) => {
                                save({
                                    ...option,
                                    selected_numerical_value: parseInt(
                                        e.target.value,
                                    ),
                                });
                            }}
                            name={option.id}
                            type="number"
                            className="w-20 px-2"
                            isFocused={false}
                            defaultValue={option.selected_numerical_value}
                        />
                        <label
                            htmlFor={option.id}
                            className="block text-sm font-medium leading-6 text-gray-900"
                        >
                            Score if selected
                        </label>
                    </div>
                    {promptType === PromptType["Multiple Select"] && (
                        <div className="flex items-center gap-x-3">
                            <Input
                                handleChange={(
                                    e: ChangeEvent<HTMLInputElement>,
                                ) => {
                                    save({
                                        ...option,
                                        unselected_numerical_value: parseInt(
                                            e.target.value,
                                        ),
                                    });
                                }}
                                name={option.id}
                                type="number"
                                className="w-20 px-2"
                                defaultValue={option.unselected_numerical_value}
                                isFocused={false}
                            />
                            <label
                                htmlFor={option.id}
                                className="block text-sm font-medium leading-6 text-gray-900"
                            >
                                Score if not selected
                            </label>
                        </div>
                    )}
                </div>
            </PopoverContent>
        </Popover>
    );
}

function MultipleChoice({ contentBlock, editable, selections }: OptionProps) {
    return (
        <div className="flex flex-col divide-y">
            {contentBlock.prompt.options
                .sort((a, b) => a.weight - b.weight)
                .map((option, index) => (
                    <div
                        className="flex items-center justify-between py-2"
                        key={option.id}
                    >
                        <div className="flex w-full items-center gap-x-3">
                            <input
                                id={option.id}
                                name={contentBlock.prompt.id}
                                value={option.id}
                                defaultChecked={
                                    !!selections?.find(
                                        (selection) =>
                                            selection.option_id === option.id &&
                                            selection.is_selected,
                                    )
                                }
                                type="radio"
                                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                            />
                            <label
                                htmlFor={editable ? "" : option.id}
                                className="block text-sm font-medium leading-6 text-gray-900"
                            >
                                <LessonRichText
                                    editable={editable}
                                    content={option.contentBlock.rich_text}
                                    contentBlock={option.contentBlock}
                                />
                            </label>
                        </div>
                        <div className="flex items-center">
                            {editable && (
                                <OptionScoring
                                    option={option}
                                    promptType={PromptType["Multiple Choice"]}
                                />
                            )}
                        </div>
                    </div>
                ))}
            {editable && <AddOption prompt={contentBlock.prompt} />}
        </div>
    );
}

function MultipleSelect({ contentBlock, editable, selections }: OptionProps) {
    return (
        <div className="flex flex-col divide-y">
            {contentBlock.prompt.options
                .sort((a, b) => a.weight - b.weight)
                .map((option) => (
                    <div
                        className="flex items-center justify-between py-2"
                        key={option.id}
                    >
                        <div className="flex w-full items-center gap-x-3">
                            {" "}
                            <input
                                defaultChecked={
                                    !!selections?.find(
                                        (selection) =>
                                            selection.option_id === option.id &&
                                            selection.is_selected,
                                    )
                                }
                                id={option.id}
                                name={option.id}
                                type="checkbox"
                                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                            />
                            <label
                                htmlFor={editable ? "" : option.id}
                                className="block text-sm font-medium leading-6 text-gray-900"
                            >
                                <LessonRichText
                                    editable={editable}
                                    content={option.contentBlock.rich_text}
                                    contentBlock={option.contentBlock}
                                />
                            </label>
                        </div>{" "}
                        <div className="flex items-center">
                            {editable && (
                                <OptionScoring
                                    option={option}
                                    promptType={PromptType["Multiple Select"]}
                                />
                            )}
                        </div>
                    </div>
                ))}
            {editable && <AddOption prompt={contentBlock.prompt} />}
        </div>
    );
}
function Text({ contentBlock, editable, selections }: OptionProps) {
    <div className="flex flex-col space-y-2"></div>;
}

function Slider({ contentBlock, editable, selections }: OptionProps) {
    const min = contentBlock?.prompt?.min || 0;
    const max = contentBlock?.prompt?.max || 100;
    const option = contentBlock.prompt.options[0];

    const selection = selections.find((s) => s.option_id === option?.id);

    const [value, setValue] = useState(
        selection?.numerical_value || Math.floor((max - min) / 2),
    );
    const leftPercent = ((value - min) / (max - min)) * 100;

    return (
        <>
            <SliderMinAndMax contentBlock={contentBlock} />
            <div className="flex flex-col space-y-2 py-6">
                <div
                    className="relative flex items-center justify-center gap-x-3"
                    key={option.id}
                >
                    {editable && (
                        <div
                            data-min-input-wrapper={contentBlock.prompt.id}
                        ></div>
                    )}
                    <div className="max-w-2x relative w-full pt-8 text-sapien-blue">
                        <div
                            className="absolute -top-[2px] flex h-8 min-w-8 items-center justify-center rounded-full
                                rounded-bl-none border-2 border-sapien-blue bg-white px-2 shadow-lg
                                translate-x-[5px]"
                            style={{
                                left: `calc(${leftPercent}% + (${10 - leftPercent * 0.2}px))`,
                            }}
                        >
                            {value}
                        </div>
                        <input
                            onChange={(e) => setValue(parseInt(e.target.value))}
                            defaultValue={value}
                            id={option.id}
                            name={option.id}
                            max={max}
                            min={min}
                            type="range"
                            className="h-4 w-full border-gray-300 text-sapien-blue focus:ring-indigo-600 "
                        />
                    </div>
                    {editable && (
                        <div
                            data-max-input-wrapper={contentBlock.prompt.id}
                        ></div>
                    )}
                </div>
            </div>
        </>
    );
}

function OptionDisplay(props: OptionProps) {
    switch (props.contentBlock.prompt.prompt_type) {
        case PromptType["Multiple Choice"]:
            return <MultipleChoice {...props} />;
        case PromptType["Multiple Select"]:
            return <MultipleSelect {...props} />;
        case PromptType["Numerical Slider"]:
            return <Slider {...props} />;
        default:
            return null;
    }
}

function AddOption({ prompt }: { prompt: PromptShape }) {
    const { mutate } = useSaveOption();

    return (
        <button
            className="border-bottom-2 my-2 flex items-center justify-center space-x-2 self-start
                rounded-md border border-sapien-blue bg-sapien-blue px-4 py-1 text-white"
            onClick={() => {
                mutate(prompt);
            }}
        >
            <span>Add Option</span>
            <PlusIcon />
        </button>
    );
}

export default function LessonQuestion(props: Props) {
    if (!props?.contentBlock?.prompt) return null;

    const selections = useSelections(props.contentBlock.prompt.id);

    return (
        <div className="flex flex-col space-y-4">
            <LessonRichText {...props} />
            <OptionDisplay {...props} selections={selections} />
        </div>
    );
}
