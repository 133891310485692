import { Dictionary } from "lodash";
import {
    CurrencyType,
    ModelVariable,
    ModelVariableDataType,
    ModelVariableResampleFunction,
    ModelVariableScope,
    ModelVariableType,
    ModelVariableUnit,
    VariableRelationship,
    VariableRelationshipOperation,
    VariableValue,
} from "../models";

// const newModelVariable: ModelVariable = new BaseModel({
//     model_block_id: "",
//     label: "",
//     weight: 0,
//     variable_type: ModelVariableType.Independent,
//     data_type: ModelVariableDataType.Number,
//     uses_time: false,
//     default_numerical_value: 0,
//     default_boolean_value: false,
//     is_integer: false,
//     is_absolute_value: false,
//     expose_to_facilitator: false,
// }) as unknown as ModelVariable;

// const newModelVariable: ModelVariable = {
//     id: null,
//     model_block_id: "",
//     label: "",
//     weight: 0,
//     variable_type: ModelVariableType.Independent,
//     data_type: ModelVariableDataType.Number,
//     uses_time: false,
//     default_numerical_value: 0,
//     default_boolean_value: false,
//     is_integer: false,
//     is_absolute_value: false,
//     expose_to_facilitator: false,
// };

// export const generateSelectionDataModelVariable = (
//     modelBlockId: string,
//     weight: number,
//     prompt: Prompt,
//     option: Option
// ): ModelVariable => {
//     let label = ""; // generate based on prompt/option/round?

//     if (
//         prompt.prompt_type === PromptType["Numerical Input"] ||
//         prompt.prompt_type === PromptType["Numerical Slider"]
//     ) {
//         label = `${displayPromptType(prompt.prompt_type)} Question (${
//             prompt.content
//         })`;
//     } else {
//         label = `${displayPromptType(prompt.prompt_type)} Question (${
//             prompt.content
//         }): Option ${(option.weight + 1).toString()} (${option.content})`;
//     }

//     let dataType = ModelVariableDataType.Number; // change to boolean for MC, MS, TS, DL
//     let defaultNumericalValue = 0; // change to prompt min for NI, NS or option weight for RO
//     let defaultBooleanValue = false; // change to true for TS first option?

//     switch (prompt.prompt_type) {
//         case PromptType["Multiple Choice"]:
//         case PromptType["Multiple Select"]:
//         case PromptType["Toggle Switch"]:
//         case PromptType["Dropdown List"]:
//             dataType = ModelVariableDataType.Boolean;
//             break;
//         case PromptType["Numerical Input"]:
//         case PromptType["Numerical Slider"]:
//             defaultNumericalValue = prompt.min;
//             break;
//         case PromptType["Rank Order"]:
//             defaultNumericalValue = option.weight;
//             break;
//         default:
//             break;
//     }

//     let modelVariable = {
//         ...newModelVariable,
//         model_block_id: modelBlockId,
//         label: label,
//         weight: weight,
//         variable_type: ModelVariableType["Selection Data"],
//         data_type: dataType,
//         default_numerical_value: defaultNumericalValue,
//         default_boolean_value: defaultBooleanValue,
//         prompt_id: prompt.id,
//         option_id: option.id,
//     };

//     return modelVariable;
// };

// export const generateNewModelVariable = (
//     modelVariableType: ModelVariableType
// ): ModelVariable => {
//     switch (modelVariableType) {
//         case ModelVariableType.Independent:
//         case ModelVariableType.Iterative:
//         case ModelVariableType.Aggregate:
//         case ModelVariableType.Algebraic:
//         case ModelVariableType.Conditional:
//         default:
//             return {} as ModelVariable;
//     }
// };

export const getDownstreamVariableIds = (
    modelVariableId: string,
    variableRelationshipsBySourceId: Dictionary<VariableRelationship[]>,
): { [index: string]: boolean } => {
    const descendantVariableIds =
        !!variableRelationshipsBySourceId &&
        !!variableRelationshipsBySourceId[modelVariableId]
            ? variableRelationshipsBySourceId[modelVariableId].reduce(
                  (map, targetRelationship) => ({
                      ...map,
                      [targetRelationship.target_variable_id]: true,
                  }),
                  {},
              )
            : {};

    if (Object.keys(descendantVariableIds).length === 0) {
        return {};
    }

    let downstreamVariableIds = {};

    Object.keys(descendantVariableIds).forEach((descendantVariableId) => {
        const nextGenerationIds = getDownstreamVariableIds(
            descendantVariableId,
            variableRelationshipsBySourceId,
        );
        downstreamVariableIds = {
            ...downstreamVariableIds,
            ...nextGenerationIds,
        };
    });

    return { ...descendantVariableIds, ...downstreamVariableIds };
};

export const getUpstreamVariableIds = (
    modelVariableId: string,
    variableRelationshipsByTargetId: Dictionary<VariableRelationship[]>,
): { [index: string]: boolean } => {
    const ancestorVariableIds =
        !!variableRelationshipsByTargetId &&
        !!variableRelationshipsByTargetId[modelVariableId]
            ? variableRelationshipsByTargetId[modelVariableId].reduce(
                  (map, sourceRelationship) => ({
                      ...map,
                      [sourceRelationship.source_variable_id]: true,
                  }),
                  {},
              )
            : {};

    if (Object.keys(ancestorVariableIds).length === 0) {
        return {};
    }

    let upstreamVariableIds = {};

    Object.keys(ancestorVariableIds).forEach((ancestorVariableId) => {
        const nextGenerationIds = getUpstreamVariableIds(
            ancestorVariableId,
            variableRelationshipsByTargetId,
        );
        upstreamVariableIds = {
            ...upstreamVariableIds,
            ...nextGenerationIds,
        };
    });

    return { ...ancestorVariableIds, ...upstreamVariableIds };
};

// helper function to generate values
export const getNewVariableValue = (
    modelVariableId: string,
    timeHorizonId: string = null,
    numericalValue: number = 0,
    booleanValue: boolean = false,
) =>
    ({
        id: null,
        model_variable_id: modelVariableId,
        numerical_value: numericalValue,
        boolean_value: booleanValue,
        time_horizon_id: timeHorizonId,
    }) as VariableValue;

// generate properties of new variables if type changes
export const setVariableProperties = (variable: ModelVariable) => {
    let baseProperties = {
        data_type:
            variable.variable_type === ModelVariableType.Logical ||
            variable.variable_type === ModelVariableType.Comparison
                ? ModelVariableDataType.Boolean
                : ModelVariableDataType.Number,
        uses_time: variable.variable_type === ModelVariableType["Iterative"],
        scope: ModelVariableScope.Team,
        default_numerical_value: 0,
        default_boolean_value: false,
        unit: null,
        is_integer: false,
        is_absolute_value: false,
        expose_to_facilitator: false,
        aggregate_operation: null,
        expose_to_designer: false,
        is_key_metric: false,
        minimum: null,
        maximum: null,
        range_step: null,
        resample_function:
            variable.variable_type === ModelVariableType.Logical ||
            variable.variable_type === ModelVariableType.Comparison
                ? ModelVariableResampleFunction.last
                : null,
    };
    switch (variable.variable_type) {
        case ModelVariableType["Independent"]:
        case ModelVariableType["Iterative"]:
        case ModelVariableType.Aggregate:
        case ModelVariableType.Algebraic:
        case ModelVariableType.Conditional:
        case ModelVariableType.Logical:
        case ModelVariableType.Comparison:
        default:
            return {
                ...variable,
                ...baseProperties,
            } as ModelVariable;
    }
};

export const getVariableRelationshipOperationKeySubset = (
    targetVariableType: ModelVariableType,
) => {
    switch (targetVariableType) {
        case ModelVariableType["Iterative"]:
        case ModelVariableType.Algebraic:
            return ["Add", "Subtract", "Multiply", "Divide", "Exponentiate"];
        case ModelVariableType.Logical:
            return [
                "Logical And",
                "Logical Or",
                "Logical And Not",
                "Logical Or Not",
            ];
        case ModelVariableType.Comparison:
            return [
                "Equal To",
                "Not Equal To",
                "Less Than",
                "Less Than Or Equal To",
                "Greater Than",
                "Greater Than Or Equal To",
            ];
        case ModelVariableType["Time Shift"]:
            return ["Shift Index", "Shift Input"];
        default:
            return [];
    }
};

// helper function to generate relationships
export const getNewVariableRelationship = (
    targetVariableId: string,
    targetVariableType: ModelVariableType,
    relationshipCount: number,
) =>
    ({
        source_variable_id: null,
        target_variable_id: targetVariableId,
        source_time_horizon_id: null,
        target_time_horizon_id: null,
        operation_type:
            targetVariableType === ModelVariableType["Iterative"] ||
            targetVariableType === ModelVariableType.Algebraic
                ? VariableRelationshipOperation.Add
                : targetVariableType === ModelVariableType.Logical
                  ? VariableRelationshipOperation["Logical And"]
                  : targetVariableType === ModelVariableType.Comparison
                    ? VariableRelationshipOperation["Equal To"]
                    : targetVariableType === ModelVariableType["Time Shift"] &&
                        relationshipCount == 0
                      ? VariableRelationshipOperation["Shift Index"]
                      : targetVariableType ===
                              ModelVariableType["Time Shift"] &&
                          relationshipCount > 0
                        ? VariableRelationshipOperation["Shift Input"]
                        : null,
        weight: relationshipCount,
    }) as VariableRelationship;

const countDecimals = (value: number) => {
    if (Math.abs(Math.floor(value) - value) > 0) {
        let splitArray = value.toString().split(".");
        return splitArray.length > 1 ? splitArray[1].length : 0;
    } else {
        return 0;
    }
};

const getCurrencySymbol = (currency: CurrencyType) => {
    switch (currency) {
        case CurrencyType.USD:
            return "$";
        case CurrencyType.INR:
            return "₹";
        default:
            return "$";
    }
};

enum LocaleType {
    enUS = "en-US",
    enIN = "en-IN",
}

const getLocale = (currency: CurrencyType) => {
    switch (currency) {
        case CurrencyType.USD:
            return LocaleType.enUS;
        case CurrencyType.INR:
            return LocaleType.enIN;
        default:
            return LocaleType.enUS;
    }
};

const formatCurrencyByType = (
    currency: CurrencyType,
    value: number,
    decimalPlaces: number,
) => {
    const currencyFormat = new Intl.NumberFormat(getLocale(currency), {
        style: "currency",
        currency: currency,
        minimumFractionDigits: decimalPlaces,
        maximumFractionDigits: decimalPlaces,
    });
    return currencyFormat.format(value);
};

const formatNumberByLocale = (
    locale: LocaleType,
    value: number,
    decimalPlaces: number,
) => {
    const numberFormat = new Intl.NumberFormat(locale, {
        minimumFractionDigits: decimalPlaces,
        maximumFractionDigits: decimalPlaces,
    });
    return numberFormat.format(value);
};

const currencyFormat = (
    currency: CurrencyType,
    num: number,
    isInteger?: boolean,
    abbrFunc?: (value: number, decimalPlaces: number) => string,
) => {
    if ("function" === typeof abbrFunc) {
        const symbol = getCurrencySymbol(currency);
        return `${symbol}${abbrFunc(num, isInteger ? 0 : 2)}`;
    }
    return formatCurrencyByType(currency, num, isInteger ? 0 : 2);
    // symbol +
    // Number(num)
    //     .toFixed(isInteger ? 0 : 2)
    //     .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
};

const percentFormat = (
    num: number,
    isInteger?: boolean,
    abbrFunc?: (value: number, decimalPlaces: number) => string,
) => {
    if ("function" === typeof abbrFunc) {
        return `${abbrFunc(num, isInteger ? 0 : 2)}%`;
    }
    return Number(num).toFixed(isInteger ? 0 : 2) + "%";
};

const noneFormat = (
    num: number,
    isInteger?: boolean,
    abbrFunc?: (value: number, decimalPlaces: number) => string,
) => {
    if ("function" === typeof abbrFunc) {
        return `${abbrFunc(num, isInteger ? 0 : 2)}`;
    }
    let decimalCount = isInteger ? 0 : Math.min(2, countDecimals(num));
    return Number(num).toFixed(decimalCount);
};

const nullFormat = (
    locale: LocaleType,
    num: number,
    isInteger?: boolean,
    abbrFunc?: (value: number, decimalPlaces: number) => string,
) => {
    if ("function" === typeof abbrFunc) {
        return `${abbrFunc(num, isInteger ? 0 : 2)}`;
    }
    let decimalCount = isInteger ? 0 : Math.min(2, countDecimals(num));
    return formatNumberByLocale(locale, num, decimalCount);
    // Number(num)
    //     .toFixed(decimalCount)
    //     .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
};

export const variableValueFormatter = (
    currency: CurrencyType,
    unitType: ModelVariableUnit,
    value: number,
    isInteger?: boolean,
    abbrFunc?: (value: number, decimalPlaces: number) => string,
): string => {
    let formattedEntry = "";
    switch (unitType) {
        case ModelVariableUnit.Dollars:
            formattedEntry = currencyFormat(
                currency,
                value,
                isInteger,
                abbrFunc,
            );
            break;
        case ModelVariableUnit.Percent:
            formattedEntry = percentFormat(value, isInteger, abbrFunc);
            break;
        case ModelVariableUnit.None:
            formattedEntry = noneFormat(value, isInteger, abbrFunc);
            break;
        default:
            formattedEntry = nullFormat(
                getLocale(currency),
                value,
                isInteger,
                abbrFunc,
            );
    }
    return formattedEntry;
};

const VariableRelationshipOperationDisplayObject: {
    [index in VariableRelationshipOperation]: string;
} = {
    // arithmetic
    [VariableRelationshipOperation["Add"]]: "\u002B",
    [VariableRelationshipOperation["Subtract"]]: "\u2212",
    [VariableRelationshipOperation["Multiply"]]: "\u00D7",
    [VariableRelationshipOperation["Divide"]]: "\u00F7",
    [VariableRelationshipOperation["Exponentiate"]]: "\u005E",
    // logical
    [VariableRelationshipOperation["Logical And"]]: "\u0026\u0026",
    [VariableRelationshipOperation["Logical Or"]]: "\u007C\u007C",
    [VariableRelationshipOperation["Logical Not"]]: "\u00AC",
    [VariableRelationshipOperation["Logical And Not"]]: "\u0026\u00AC",
    [VariableRelationshipOperation["Logical Or Not"]]: "\u007C\u00AC",
    // comparison
    [VariableRelationshipOperation["Equal To"]]: "\u003D",
    [VariableRelationshipOperation["Not Equal To"]]: "\u2260",
    [VariableRelationshipOperation["Less Than"]]: "\u003C",
    [VariableRelationshipOperation["Less Than Or Equal To"]]: "\u2264",
    [VariableRelationshipOperation["Greater Than"]]: "\u003E",
    [VariableRelationshipOperation["Greater Than Or Equal To"]]: "\u2265",
    // time shift
    [VariableRelationshipOperation["Shift Index"]]: "index",
    [VariableRelationshipOperation["Shift Input"]]: "input",
};

export const displayVariableRelationshipOperation = (
    variableRelationshipOperation: VariableRelationshipOperation,
): string => {
    return (
        VariableRelationshipOperationDisplayObject[
            variableRelationshipOperation
        ] || ""
    );
};
