import { BaseModel, IBaseModel } from "./BaseModel";
import { ModelVariable } from "./ModelVariable";
import { ContentBlockShape } from "./ContentBlock";

export type OptionShape = {
    id?: string;
    prompt_id: string;
    content: string;
    numerical_value: number;
    weight: number;
    contentBlock?: ContentBlockShape;
    size?: number;
    drag_and_drop_prompt_container_id?: string;
    content_block_id?: string;
    selectionDataModelVariable?: ModelVariable;
    selected_numerical_value?: number;
    unselected_numerical_value?: number;
};

export class Option extends BaseModel implements IBaseModel, OptionShape {
    constructor(data = null) {
        super();
        if (data) {
            this.setUp(data);
        }
    }

    setUp(data) {
        if (data) {
            for (let prop in data) {
                this[prop] = data[prop];
            }
        }
    }

    public static CLASSNAME: string = "Option";
    public static END_POINT: string = "/option_service/";

    weight: number = 0; // TODO: ADD TO DATABASE
    prompt_id: string = "";
    content: string = "";
    numerical_value: number = 0; // TODO: ADD TO DATABASE
    is_correct: boolean = false; // TODO: ADD TO DATABASE
    contentBlock: ContentBlockShape = null;
    selectionDataModelVariable?: ModelVariable;
    size?: number;
    drag_and_drop_prompt_container_id?: string;
    content_block_id?: string;
    selected_numerical_value?: number;
    unselected_numerical_value?: number;
}
