import { RequestPayload, VisitHelperOptions } from "@inertiajs/core";
import {
    LaravelParams,
    LaravelRequestBodyShapes,
    LaravelRoutes,
} from "../../ziggy-shims";
import { route, Config } from "ziggy-js";
import { socket } from "@/hooks/websockets/useWebsockets";
import { SapienPage } from "../types";
import { Ziggy } from "@/ziggy-definitions";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { router } from "@inertiajs/react";

function headers() {
    return {
        "x-game-id": localStorage.getItem("game_id") || "undefined",
        "x-team-id": localStorage.getItem("team_id") || "undefined",
        "x-role-id": localStorage.getItem("role_id") || "undefined",
        "x-socket-id": socket.connection.socket_id,
        "x-inertia-version": window["inertiaVersion"],
    };
}

export function sapienRoute<RouteKey extends keyof LaravelRoutes>(
    name: RouteKey,
    params?: (typeof LaravelParams)[RouteKey],
    absolute?: boolean,
    customZiggy?: Config,
): string {
    return route(
        name as unknown as string | undefined,
        params,
        absolute,
        customZiggy,
    ) as string;
}

export function isCurrent(
    possibleCurrentRoute: keyof LaravelRoutes,
    currentRoute: keyof LaravelRoutes,
    subRoutes?: (keyof LaravelRoutes)[],
) {
    return (
        possibleCurrentRoute === currentRoute ||
        subRoutes?.includes(currentRoute)
    );
}

type SapienOptions = Exclude<
    VisitHelperOptions,
    "method" | "data" | "onSuccess"
> & {
    onSuccess?: <T extends SapienPage>(page: T) => void;
};

export const SapienInertia = {
    ...router,
    ...{
        get(
            url: URL | string,
            data?: RequestPayload,
            options?: Exclude<
                VisitHelperOptions,
                "method" | "data" | "preserveScroll"
            >,
        ) {
            return router.get(url, data, {
                ...options,
                ...{ headers: { ...options?.headers, ...headers() } },
            });
        },
        reload(options?: Exclude<VisitHelperOptions, "method" | "data">) {
            return router.reload({
                ...options,
                ...{ headers: { ...options?.headers, ...headers() } },
            });
        },
        //replace(url: URL | string, options?: Exclude<VisitHelperOptions, 'replace'>): void;
        post<RouteKey extends keyof typeof LaravelRequestBodyShapes>(
            route: RouteKey,
            data?: Partial<(typeof LaravelRequestBodyShapes)[RouteKey]>,
            params?: (typeof LaravelParams)[RouteKey],
            options?: SapienOptions,
        ) {
            const url = sapienRoute(route, params, false, Ziggy as Config);
            return router.post(url, data as any, {
                ...options,
                ...{ headers: { ...options?.headers, ...headers() } },
            });
        },
        put<RouteKey extends keyof typeof LaravelRequestBodyShapes>(
            route: RouteKey,
            data?: Partial<(typeof LaravelRequestBodyShapes)[RouteKey]>,
            params?: (typeof LaravelParams)[RouteKey],
            options?: Exclude<VisitHelperOptions, "method" | "data">,
        ) {
            const url = sapienRoute(route, params, false, Ziggy as Config);
            return router.put(url as string, data as any, {
                ...options,
                ...{ headers: { ...options?.headers, ...headers() } },
            });
        },
        patch(
            url: URL | string,
            data?: any,
            options?: Exclude<VisitHelperOptions, "method" | "data">,
        ) {
            return router.patch(url, data, {
                ...options,
                ...{ headers: { ...options?.headers, ...headers() } },
            });
        },

        delete(
            url: URL | string,
            options?: Exclude<VisitHelperOptions, "method" | "data">,
        ) {
            return router.delete(url, {
                ...options,
                ...{ headers: { ...options?.headers, ...headers() } },
            });
        },
        visit(
            route: string,
            options?: Exclude<VisitHelperOptions, "method" | "data">,
        ) {
            return router.visit(route, {
                ...options,
                ...{ headers: { ...options?.headers, ...headers() } },
            });
        },
    },
};

type RouteKeyType = keyof typeof LaravelRequestBodyShapes;
const keys = Object.keys(LaravelRequestBodyShapes) as RouteKeyType[];
type Key = (typeof keys)[number];

type ParamKeyType = keyof typeof LaravelParams;
const paramKeys = Object.keys(LaravelParams) as ParamKeyType[];
type ParamKey = (typeof paramKeys)[number];

export const sapienAxios = {
    get<ResponseBodyType = {}, RouteKey extends ParamKey = ParamKey>(
        route: RouteKey,
        params?: (typeof LaravelParams)[RouteKey],
    ) {
        const url = sapienRoute(route, params, false, Ziggy as Config);
        return axios.get<ResponseBodyType>(url);
    },
    async post<ResponseBodyType = {}, RouteKey extends Key = Key>(
        route: RouteKey,
        data?: Partial<(typeof LaravelRequestBodyShapes)[RouteKey]>,
        params?: (typeof LaravelParams)[RouteKey],
        config?: AxiosRequestConfig,
    ): Promise<AxiosResponse<ResponseBodyType>> {
        const url = sapienRoute(route, params, false, Ziggy as Config);
        return axios.post<ResponseBodyType>(url, data, config);
    },
    async put<
        ResponseBodyType = {},
        RouteKey extends
            keyof typeof LaravelRequestBodyShapes = "creator.design.store.modal",
    >(
        route: RouteKey,
        data?: Partial<(typeof LaravelRequestBodyShapes)[RouteKey]>,
        params?: (typeof LaravelParams)[RouteKey],
        config?: AxiosRequestConfig,
    ) {
        const url = sapienRoute(route, params, false, Ziggy as Config);
        return axios.put<ResponseBodyType>(url, data, config);
    },
    async delete<ResponseBodyType = {}, RouteKey extends ParamKey = ParamKey>(
        route: RouteKey,
        params?: (typeof LaravelParams)[RouteKey],
        config?: AxiosRequestConfig,
    ): Promise<AxiosResponse<ResponseBodyType>> {
        const url = sapienRoute(route, params, false, Ziggy as Config);
        return axios.delete(url, config);
    },
    patch(url: string, data?: any) {
        return axios.patch(url, data);
    },
};
