import React, { FC, ReactNode, useMemo, useRef } from "react";
import { Link as InertiaLink } from "@inertiajs/react";
import { route } from "ziggy-js";
import { ThemeObject, Display, AlignItems } from "../ThemeObjects";
import { StyledUniversalLinkDiv } from "./Universal";
import { ContentBlockEvents } from "./ContentBlockEvents";
import { useContentBlockBehaviors } from "@/hooks/useContentBlockBehaviors";

type StyledLinkProps = ContentBlockEvents & {
    theme: Partial<ThemeObject>;
    linkTarget: string;
    children?: ReactNode;
    contentBlockId: string;
    isSidebarOpen: boolean;
};

// function hasParent(
//     element: HTMLElement,
//     parentSelector: string,
//     parentElement: HTMLElement = null
// ) {
//     if (!parentElement) parentElement = element;

//     const potentialParents = document.querySelectorAll(parentSelector);
//     console.log(potentialParents, element);
//     for (let i in potentialParents.entries()) {
//         console.log(potentialParents[i], typeof potentialParents[i]);
//         if (potentialParents[i].contains(element)) return true;
//     }

//     return (
//         element?.parentElement &&
//         hasParent(element, parentSelector, element.parentElement)
//     );
// }

function getUrl(linkTarget: string) {
    return `/participant/${
        (route().params as any).simulationSlug
    }${linkTarget}`;
}

function getIsCurrentRoute(linkTarget: string, isInEditorContext = false) {
    if (!linkTarget || isInEditorContext) return false;
    console.log(route(), linkTarget);
    const param = (route().params as any).navigationSlug;
    let strippedTarget = linkTarget.replace("/", "");
    if (!param) return !strippedTarget;
    return strippedTarget === param;
}

export const StyledLink: FC<StyledLinkProps> = ({
    theme,
    linkTarget,
    children,
    contentBlockId,
    onHover,
    isSidebarOpen,
    isInEditorContext,
}) => {
    const { clickFunction, isLinkWithBehaviors, method, linkUrl, behaviors } =
        useContentBlockBehaviors(contentBlockId);

    const linkRef = useRef<HTMLDivElement>();

    const url = useMemo(() => {
        if (linkUrl) {
            return linkUrl;
        }
        if (isLinkWithBehaviors || "function" === typeof clickFunction) {
            return false;
        }

        return getUrl(linkTarget);
    }, [contentBlockId, clickFunction, linkUrl, method, isLinkWithBehaviors]);

    const themeWithOpacityFixed = useMemo(() => {
        return { ...theme, opacity: isInEditorContext ? 1 : 0.75 };
    }, [isInEditorContext, theme]);

    return isInEditorContext ? (
        <StyledUniversalLinkDiv
            role="button"
            ref={linkRef}
            {...themeWithOpacityFixed}
            data-content-block-id={contentBlockId}
            p={0}
            onClick={clickFunction}
        >
            <div
                role="link"
                className={`flex cursor-pointer flex-row space-x-2 ${
                    getIsCurrentRoute(linkTarget, isInEditorContext)
                        ? " active"
                        : " inactive"
                }`}
            >
                {children}
            </div>
        </StyledUniversalLinkDiv>
    ) : (
        <StyledUniversalLinkDiv
            ref={linkRef}
            as={`${!!url ? "div" : "button"}`}
            {...theme}
            data-content-block-id={contentBlockId}
            p={0}
            onClick={(e) => {
                console.log("evetn", e);
                clickFunction(e);
            }}
            className="cursor-pointer"
        >
            {!!behaviors?.click?.ExternalLink ? (
                <a
                    className={`flex flex-row space-x-2
                    ${getIsCurrentRoute(linkTarget) ? " active" : " inactive"}`}
                    href={url as string}
                    target="_blank"
                >
                    {children}
                </a>
            ) : !!url ? (
                <InertiaLink
                    className={`flex flex-row space-x-2
                        ${
                            getIsCurrentRoute(linkTarget)
                                ? " active"
                                : " inactive"
                        }`}
                    href={url as string}
                >
                    <span className=" pointer-events-none">{children}</span>
                </InertiaLink>
            ) : (
                <div
                    role="link"
                    className="flex cursor-pointer flex-row space-x-2"
                >
                    <span className=" pointer-events-none">{children}</span>
                </div>
            )}
        </StyledUniversalLinkDiv>
    );
};

export const defaultLinkTheme: Partial<ThemeObject> = {
    display: Display["inline-flex"],
    cursor: "pointer",
    alignItems: AlignItems.center,
    // fontFamily: "0",
    // fontSize: 3,
    fontWeight: 4,
    letterSpacing: 4,
    // fontStyle: FontStyle.normal,
    color: "#000000",
    activeColor: "#0000ff",
    hoverColor: "#0000ff",
    py: 0,
    px: 4,
    ml: 0,
    mr: 0,
    mt: 0,
    mb: 0,
    iconPosition: "before",
};
