import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
    LeftMenuSectionType,
    RightMenuSectionType,
} from "@/Pages/Admin/editor";
import { ContentBlock, ContentBlockShape } from "../models";
import { uploadComplete } from "./uploadFileSlice";
import { SapienInertia, sapienRoute } from "@/inertia-utils/hooks";

type ContentEditorSliceState = {
    activeContentBlock: ContentBlock | undefined;
    chartContentBlock: ContentBlock | ContentBlockShape | null;
    chartContentBlockChildId: string | null;
    modelDataContentBlockId: string | null;
    activeId: string;
    hasUnsavedChanges: boolean;
    originalContentBlock: ContentBlock | undefined;
    imageContentBlockId: string;
    videoContentBlockId: string;
    backgroundImageContentBlockId: string;
    uploadedFileUrl: string;
    activeLineage: { [index: string]: any };
    leftMenuSection: LeftMenuSectionType;
    rightMenuSection: RightMenuSectionType;
};

const initialState: ContentEditorSliceState = {
    activeContentBlock: undefined,
    chartContentBlock: null,
    chartContentBlockChildId: null,
    modelDataContentBlockId: null,
    activeId: "",
    hasUnsavedChanges: false,
    originalContentBlock: undefined,
    imageContentBlockId: "",
    videoContentBlockId: "",
    backgroundImageContentBlockId: "",
    uploadedFileUrl: "",
    activeLineage: {},
    leftMenuSection: "layers",
    rightMenuSection: "style",
};

const contentEditorDataSlice = createSlice({
    name: "ContentEditorStore",
    initialState,
    reducers: {
        setActiveContentBlock(state, { payload }: PayloadAction<ContentBlock>) {
            state.activeContentBlock = payload;
        },
        discardActiveContentBlock(
            state,
            { payload }: PayloadAction<undefined>
        ) {
            state.activeContentBlock = payload;
            state.activeId = "";
            state.hasUnsavedChanges = false;
            state.originalContentBlock = undefined;
        },
        changeActiveContentBlock(
            state,
            { payload }: PayloadAction<ContentBlock>
        ) {
            state.activeContentBlock = payload;
            state.activeId = payload.id;
            state.originalContentBlock = payload;
        },
        setHasUnsavedChanges(state, { payload }: PayloadAction<boolean>) {
            state.hasUnsavedChanges = payload;
        },
        setImageContentBlockId(state, { payload }: PayloadAction<string>) {
            state.imageContentBlockId = payload;
            //TODO: do this a more reduxy way
            SapienInertia.get(
                sapienRoute("creator.design.image.edit", {
                    contentBlockId: payload,
                    mediaType: "image",
                })
            );
        },
        setVideoContentBlockId(state, { payload }: PayloadAction<string>) {
            state.videoContentBlockId = payload;
        },
        setBacgkgroundImageContentBlockId(
            state,
            { payload }: PayloadAction<string>
        ) {
            state.backgroundImageContentBlockId = payload;
        },
        setActiveLineage(
            state,
            { payload }: PayloadAction<{ [index: string]: any }>
        ) {
            state.activeLineage = payload;
        },
        setLeftMenuSection(
            state,
            { payload }: PayloadAction<LeftMenuSectionType>
        ) {
            state.leftMenuSection = payload;
        },
        setRightMenuSection(
            state,
            { payload }: PayloadAction<RightMenuSectionType>
        ) {
            state.rightMenuSection = payload;
        },
        setChartContentBlock(
            state,
            { payload }: PayloadAction<ContentBlock | ContentBlockShape | null>
        ) {
            state.chartContentBlockChildId = null;
            state.modelDataContentBlockId = null;
            state.chartContentBlock = payload;
        },
        setChartContentBlockChildId(
            state,
            { payload }: PayloadAction<string | null>
        ) {
            state.chartContentBlockChildId = payload;
        },
        setModelDataContentBlockId(
            state,
            { payload }: PayloadAction<string | null>
        ) {
            state.modelDataContentBlockId = payload;
        },
        setUploadedFileUrl(state, { payload }: PayloadAction<string | null>) {
            state.uploadedFileUrl = payload;
        },
    },
    extraReducers: {
        [uploadComplete.toString()](state, { payload }: PayloadAction<string>) {
            state.uploadedFileUrl = payload;
        },
    },
});

export const contentEditorStoreReducer = contentEditorDataSlice.reducer;

export const {
    setActiveContentBlock,
    discardActiveContentBlock,
    changeActiveContentBlock,
    setHasUnsavedChanges,
    setImageContentBlockId,
    setVideoContentBlockId,
    setBacgkgroundImageContentBlockId,
    setActiveLineage,
    setLeftMenuSection,
    setRightMenuSection,
    setChartContentBlock,
    setChartContentBlockChildId,
    setModelDataContentBlockId,
    setUploadedFileUrl,
} = contentEditorDataSlice.actions;
